/*
 * @Description: 服务器
 * @Author: ZY
 * @Date: 2020-12-28 14:40:50
 * @LastEditors: scy😊
 * @LastEditTime: 2021-01-20 10:17:09
 */
import https from '@/utils/https'
import { ContentType, Method } from 'axios-mapper'

export const ServerManage_GMGetProcessState = () => {
  return https().request<IHttpResponse>('/GMGetProcessState', Method.POST, {}, ContentType.json)
}

export function ServerManage_GMProcessEdit  (data: IC2G_GMProcessEdit)  {
  return https().request<IHttpResponse>('/GMProcessEdit', Method.POST, data, ContentType.json)
}

export function ServerManage_GMHandleAllProcess  (data: IC2G_GMHandleAllProcess)  {
  return https().request<IHttpResponse>('/GMHandleAllProcess', Method.POST, data, ContentType.json)
}

export const ServerManage_GMGetLogDBInfo = () => {
  return https().request<IHttpResponse>('/GMGetLogDBInfo', Method.POST, {}, ContentType.json)
}

export const ServerManage_GMSearchLog = (d:IC2G_GMSearchLog) => {
  return https().request<IG2C_GMSearchLog>('/GMSearchLog', Method.POST, d, ContentType.json)
}

export const ServerManage_GMIgnoreErrorLog = (d:IC2G_GMIgnoreErrorLog) => {
  return https().request<IHttpResponse>('/GMIgnoreErrorLog', Method.POST, d, ContentType.json)
}



export const ServerManage_GMGetServerZoneInfo = () => {
  return https().request<IG2C_GMLogin>('/GMGetServerZoneInfo', Method.POST, {}, ContentType.json)
}

export const ServerManage_GMSearchServerZone = (data: IC2G_GMSearchServerZone) => {
  return https().request<IG2C_GMSearchServerZone>('/GMSearchServerZone', Method.POST, data, ContentType.json)
}

export const ServerManage_GMAddNewServerZone = (data: IC2G_GMAddNewServerZone) => {
  return https().request<IHttpResponse>("/GMAddNewServerZone", Method.POST,data, ContentType.json)
}

export const ServerManage_GMEditServerZone = (data:IC2G_GMEditServerZone) => {
  return https().request<IHttpResponse>("/GMEditServerZone", Method.POST, data, ContentType.json)
}

