



export function TodayStart() {
    return new Date(new Date(new Date().toLocaleDateString()).getTime());
}

export function TodayEnd() {
    return new Date(new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1);
}