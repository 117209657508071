
import { ServerManage_GMGetLogDBInfo, ServerManage_GMIgnoreErrorLog, ServerManage_GMSearchLog } from '@/apis/server_manage';
import { formatJson } from "@/utils";
import { exportJson2Excel } from "@/utils/excel";
import { TodayEnd, TodayStart } from "@/utils/timer";
import { ElForm, ElMessage, ElMessageBox } from 'element-plus';
import { defineComponent, nextTick, onMounted, reactive, ref, toRefs } from 'vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({

  setup() {
    const { t } = useI18n()
    const dataForm = ref<typeof ElForm>()
    const dataMap = reactive({
      time: Date,
      pickerOptions: {
        disabledDate(time: Date) {
          return time.getTime() > Date.now()
        }
      },
      logLevelOptions: {
        "Debug": 2,
        "Info": 3,
        "Warning": 4,
        "Error": 5,
        "Important": 9,
      } as { [key: string]: number },
      tableKey: 0,
      list: [] as IDBLogRecord[],
      total: 1,
      listLoading: true,
      logdbInfoLoading: false,
      starttime: TodayStart(),
      endtime: TodayEnd(),

      listQuery: {
        page: 1,
        limit: 20,
        logLevel: "Error",
        title: "",
        label: "",
        process: "",
        sort: "+id"
      },
      processOptions: [] as ILogProcessData[],
      labelOptions: [""],
      dialogFormVisible: false,

      rules: {
        process: [
          { required: true, message: "process is required", trigger: "change" }
        ],
        logLevel: [
          { required: true, message: "logLevel is required", trigger: "change" }
        ]
      },

      downloadLoading: false,
      tempLogRecord: { FullMsg: [] as string[] },
    })

    const getLogDBInfo = async () => {
      dataMap.logdbInfoLoading = true;
      let cbmsg = await ServerManage_GMGetLogDBInfo()
      console.log(cbmsg);
      if (cbmsg?.Error !== 0) {
        ElMessage.error(`GetLogDBFail: ${cbmsg?.Message}`);
        return;
      }
      const items = JSON.parse(cbmsg?.Message!) as ILogInfoData;
      items.Process.forEach(element => {
        dataMap.processOptions.push(element);
      });
      dataMap.listQuery.process =
        dataMap.processOptions[0].ProcessDes + "_" + dataMap.processOptions[0].Id;
      items.labels.forEach(element => {
        dataMap.labelOptions.push(element);
      });
      dataMap.logdbInfoLoading = false;
      await getLogList();
    };
    const handleCurrentChange = (page: number) => {
      dataMap.listQuery.page = page;
      getLogList();
    };
    const handleSizeChange = (val: any) => {
      // dataMap.getList(null, null, val)
    };
    const getLogList = async () => {
      dataMap.listLoading = true;
      dataMap.list = [];
      let msg = {} as IC2G_GMSearchLog;
      msg.StartTime = Math.ceil(dataMap.starttime.getTime() / 1000);
      msg.EndTime = Math.ceil(dataMap.endtime.getTime() / 1000);
      msg.LogLevel = dataMap.logLevelOptions[dataMap.listQuery.logLevel] || 2;
      msg.PageCount = dataMap.listQuery.limit;
      msg.PageIndex = dataMap.listQuery.page;
      for (let info of dataMap.processOptions) {
        if (info.ProcessDes + "_" + info.Id == dataMap.listQuery.process) {
          msg.ProcessId = info.AppName + "_" + info.Id;
          break;
        }
      }
      if (dataMap.listQuery.label !== "") {
        msg.Label = dataMap.listQuery.label;
      }
      if (dataMap.listQuery.title !== "") {
        msg.Title = dataMap.listQuery.title;
      }
      let cbmsg = await ServerManage_GMSearchLog(msg);
      if (cbmsg?.Error !== 0) {
        ElMessage.error(`GetLogFail: ${cbmsg?.Message}`);
        return;
      }
      if (cbmsg.SearchResult) {
        for (let k of cbmsg.SearchResult) {
          let record = JSON.parse(k) as IDBLogRecord;
          record.Id = record._id;
          record.Count = record.Count || 1;
          record.Index = dataMap.list.length + 1 + (dataMap.listQuery.page - 1) * dataMap.listQuery.limit;
          dataMap.list.push(record);
        }
      }
      dataMap.total = cbmsg.SearchCount;
      dataMap.listLoading = false;
    }

    const pickerStartOptions = {
      disabledDate(time: Date) {
        return time.getTime() > Date.now();
      }
    };

    const pickerEndOptions = {
      disabledDate(time: Date) {
        return time.getTime() > Date.now();
      }
    };
    const GetShotMsg = (row: IDBLogRecord) => {
      if (row == null || row.Msg == null) { return "" }
      let arr = row.Msg.split("\r\n");
      if (arr.length >= 3) {
        let i = 0;
        let r = "";
        while (i < 3) {
          r += arr[i] + "<br>";
          i++;
        }
        return r;
      } else {
        return row.Msg;
      }
    }
    const GetFullMsg = (row: IDBLogRecord) => {
      if (row == null || row.Msg == null) { return [] }
      let arr = row.Msg.split("\r\n");
      // let i = 0;
      // let r = [];
      // while (i < arr.length) {
      //   r .push(arr[i] + "<br>";
      //   i++;
      // }
      return arr;
    }

    const handleSingleLogInfo = (row: IDBLogRecord) => {
      dataMap.tempLogRecord.FullMsg = GetFullMsg(row);
      dataMap.dialogFormVisible = true;
      nextTick(() => {
        (dataForm.value as any).clearValidate()
      });
    }
    const handleIgoneLog = (row: IDBLogRecord) => {
      ElMessageBox.confirm(
        `Confirm to Delete ErrorLog<${row.Id}> ?`,
        "Delete ErrorLog",
        {
          confirmButtonText: "Confirm",
          cancelButtonText: "Cancel",
          type: "warning"
        }
      )
        .then(async () => {
          let msg = {} as IC2G_GMIgnoreErrorLog;
          msg.LogId = row.Id;
          msg.LogTime = Math.ceil(parseInt(row.Time) / 1000);
          msg.LogProcess = row.Process;
          let cbmsg = await ServerManage_GMIgnoreErrorLog(msg);
          if (cbmsg?.Error == 0) {
            ElMessage.success(`Log<${row.Id}> Delete ErrorLog`)
            row.Level = 0;
          } else {
            ElMessage.error(`Log<${row.Id}> Delete ErrorLog`)
          }
        })
        .catch(err => {
          console.error(err);
        });
    }

    const handleDownload = () => {
      dataMap.downloadLoading = true;
      const tHeader = ["Id", "Process", "Time", "Level", "Count", "Label", "Msg"];
      const filterVal = ["Id", "Process", "Time", "Level", "Count", "Label", "Msg"];
      const data = formatJson(filterVal, dataMap.list);
      exportJson2Excel(tHeader, data, "ServerLogDownload");
      dataMap.downloadLoading = false;
    }

    onMounted(() => {
      getLogDBInfo()
    })

    return {
      t, ...toRefs(dataMap),
      dataForm,
      getLogList,
      GetShotMsg,
      pickerStartOptions,
      pickerEndOptions,
      handleCurrentChange,
      handleSizeChange,
      handleSingleLogInfo,
      handleIgoneLog,
      handleDownload
    }
  }
})

